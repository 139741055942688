<template>
  <div class="about">
    <img
      class="logo"
      src="../assets/image/bn3.jpg"
      alt=""
    />
    <div class="introduce" id="introduction">
      <h1>公司简介</h1>
      <p class="text">
        贵州拓宇创业科技有限公司成立于2019年07月09日，注册于贵州省贵阳市国家高新技术产业开发区金阳科技产业园贵阳科技大厦A栋9楼。拓宇创业是一家以人脸识别嵌入式技术开发为核心，集软硬件研发、生产、销售为一体的高新科技企业，主营各类人脸识别人证核验比对登记访客机、触摸一体机、智能排队机、触摸查询机、自助终端机、广告机等，为打造智慧城市提供智能终端设备，提供加工定制服务等，提供一体化科技智能解决方案。
      </p>
      <!-- <p class="text">我们的服务宗旨:持续为客户创造最优质的服务</p>
      <p class="text">
        感谢您选择，每一次倾心的合作都是一个全新的体会和挑战，让我们从沟通开始这次愉快的合作吧！
      </p> -->
      <div class="img_box">
        <div
          class="img_title"
          v-for="purpose in purposeData"
          :key="purpose.text1"
        >
          <div class="img">
            <img
              :src="purpose.url"
              alt=""
            />
          </div>
          <p class="img_text">
            {{purpose.text1}}<br />{{purpose.text2}}
          </p>
        </div>
      </div>
    </div>
    <div class="service_box" id="course">
      <div class="service">
        <h1>
          <p class="name">服务优势</p>
          <p class="text">创意、流程、执行缺一不可</p>
        </h1>
        <div class="service_content">
          <div
            class="service_title"
            v-for="service in serviceData"
            :key="service.text"
          >
            <div class="img">
              <img
                :src="service.url"
                alt=""
              >
            </div>
            <p class="text">
              {{service.text}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="group" id="team">
      <h1>
        <p class="name">我们的团队</p>
        <p class="text">我们是追求品质与力求不断超越自己的团队，每一位成员也是亲密的伙伴，与公司一起成长与发展。我们尊重每次合作的机会与挑战，不断精进，力求完美。团队秉承专注、专业的设计服务思维，让客户通过设计发挥产品的最大价值，并发掘无限的可能。热爱设计并坚信设计的力量让我们不断前进…</p>
      </h1>
      <div class="group_content">
        <div class="group_left">
          <img
            src="../assets/image/team_2.jpg"
            alt=""
          >
          <img
            src="../assets/image/team_3.jpg"
            alt=""
          >
          <img
            src="../assets/image/team_4.jpg"
            alt=""
          >
          <img
            src="../assets/image/team_5.jpg"
            alt=""
          >
        </div>
        <div class="group_right">
          <img
            src="../assets/image/team_6.jpg"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="service_box" id="course">
      <div class="service">
        <h1>
          <p class="name">我们的活动</p>
          <!-- <p class="text">创意、流程、执行缺一不可</p> -->
        </h1>
        <ActivitySwiper :screenWidth="screenWidth" />
      </div>
    </div>
    <!-- 页尾 -->
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Public/Footer.vue'
import ActivitySwiper from "../components/Home/ActivitySwiper.vue";

export default {
  name: "About",
  components: {
    ActivitySwiper,
    Footer
  },
  data() {
    return {
      purposeData: [
        {
          url: require("../assets/image/guanyu_04.jpg"),
          text1: "我们沉浸于用户体验",
          text2: "帮您挖掘和创新更大商机的可能",
        },
        {
          url: require("../assets/image/guanyu_06.jpg"),
          text1: "服务思维下的商业设计",
          text2: "撇弃浮夸去直达共同的目标",
        },
        {
          url: require("../assets/image/guanyu_09.jpg"),
          text1: "精益合作和分享的态度",
          text2: "让我们鼎力相助你的梦想",
        },
      ],
      serviceData: [
        {
          url: require('../assets/image/xiangmu_03.jpg'),
          text: "从创始至今，我们的业务方向始终不变，专注做好一件事才能更好的追求高品质，这也是我们存在的价值。在未来我们也是基于此信念而继续创造。"
        },
        {
          url: require('../assets/image/xiangmu_05.jpg'),
          text: "在不断的创新和实践中总结出可持续和可信赖的设计流程，坚持与用户一起思考，用设计的方法发现问题、解决问题、输出设计方案，并实现客户产品和企业价值的提升。"
        },
        {
          url: require('../assets/image/xiangmu_07.jpg'),
          text: "多年来积累了大量优秀的项目案例，并验证了设计的价值。在不断的探索中总结经验，以结果为导向，继续为更多客户提供优秀的设计。"
        },
      ],
      screenWidth: 0,
      displays: true,
    };
  },
  watch: {
    screenWidth(val) {
      if (val <= 1024) {
        this.displays = false;
      } else {
        this.displays = true;
      }
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
};
</script>

<style lang="scss">
.about {
  padding-top: 60px;
  height: auto;
  .logo {
    width: 100vw;
  }
  .introduce {
    width: 1160px;
    padding: 50px 0;
    margin: auto;
    h1 {
      font-size: 28px;
      color: #333;
      font-weight: normal;
      text-align: center;
    }
    .text {
      margin: 0px auto;
      overflow: hidden;
      line-height: 30px;
      font-size: 14px;
      color: #333;
      margin-top: 30px;
      text-indent: 2em;
    }
    .img_box {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .img {
        width: auto;
        img {
          width: auto;
          height: 230px;
        }
      }
      .img_text {
        margin-top: 10px;
        font-size: 18px;
        line-height: 32px;
        color: #333;
        font-weight: normal;
        text-align: center;
      }
    }
  }
  .service_box {
    width: 100vw;
    height: auto;
    padding: 50px 0;
    background-color: #a1a1a1;
    .service {
      width: 1160px;
      height: auto;
      margin: auto;
      h1 {
        text-align: center;
        font-weight: normal;
        .name {
          font-size: 28px;
          color: #333;
          line-height: 35px;
          margin: 15px 0;
        }
        .text {
          font-size: 16px;
          color: #666;
        }
      }
      .service_content {
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .service_title {
          width: 32%;
          height: auto;
          margin-top: 20px;
          cursor: pointer;
          .img {
            width: 100%;
            height: 235px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            text-indent: 2em;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            font-size: 14px;
            margin-top: 15px;
            color: #333;
            line-height: 26px;
          }
          &:hover {
            .img {
              box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16),
                0px 2px 10px 0px rgba(0, 0, 0, 0.12);
            }
          }
        }
      }
    }
  }
  .group {
    width: 1160px;
    padding: 50px 0;
    margin: auto;
    h1 {
      font-weight: normal;
      .name {
        font-size: 28px;
        color: #333;
        line-height: 35px;
        margin: 15px 0;
        text-align: center;
      }
      .text {
        width: 95%;
        font-size: 16px;
        color: #666;
        margin: auto;
      }
    }
    .group_content {
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .group_left {
        width: 70%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        grid-row-gap: 1px;
        grid-column-gap: 1px;
        img {
          width: 49.9%;
        }
      }
      .group_right {
        width: 29.8%;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  .about {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
    }
    .introduce {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-size: 28px;
        color: #333;
        font-weight: normal;
        text-align: center;
      }
      .text {
        margin: 0px auto;
        overflow: hidden;
        line-height: 30px;
        font-size: 14px;
        color: #333;
        margin-top: 30px;
        text-indent: 2em;
      }
      .img_box {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .img_title {
          width: 32%;
          .img {
            width: 100%;
            text-align: center;
            img {
              width: auto;
              height: 100%;
            }
          }
          .img_text {
            margin-top: 10px;
            font-size: 15px;
            line-height: 32px;
            color: #333;
            font-weight: normal;
            text-align: center;
          }
        }
      }
    }
    .service_box {
      width: 100vw;
      height: auto;
      padding: 50px 0;
      background-color: #a1a1a1;
      .service {
        width: 90vw;
        height: auto;
        margin: auto;
        h1 {
          text-align: center;
          font-weight: normal;
          .name {
            font-size: 28px;
            color: #333;
            line-height: 35px;
            margin: 15px 0;
          }
          .text {
            font-size: 16px;
            color: #666;
          }
        }
        .service_content {
          width: 100%;
          height: auto;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          .service_title {
            width: 32%;
            height: auto;
            margin-top: 20px;
            cursor: pointer;
            .img {
              width: 100%;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              text-indent: 2em;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              font-size: 14px;
              margin-top: 15px;
              color: #333;
              line-height: 26px;
            }
            &:hover {
              .img {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    .group {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-weight: normal;
        .name {
          font-size: 28px;
          color: #333;
          line-height: 35px;
          margin: 15px 0;
          text-align: center;
        }
        .text {
          width: 95%;
          font-size: 16px;
          color: #666;
          margin: auto;
        }
      }
      .group_content {
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .group_left {
          width: 70%;
          height: auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          grid-row-gap: 1px;
          grid-column-gap: 1px;
          img {
            width: 49.7%;
            height: auto;
          }
        }
        .group_right {
          width: 29.8%;
          overflow: hidden;
          img {
            height: 100%;
            position: relative;
            left: -50%;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .about {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
    }
    .introduce {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-size: 28px;
        color: #333;
        font-weight: normal;
        text-align: center;
      }
      .text {
        margin: 0px auto;
        overflow: hidden;
        line-height: 30px;
        font-size: 14px;
        color: #333;
        margin-top: 30px;
        text-indent: 2em;
      }
      .img_box {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .img_title {
          width: 32%;
          .img {
            width: 100%;
            text-align: center;
            img {
              width: auto;
              height: 100%;
            }
          }
          .img_text {
            margin-top: 10px;
            font-size: 15px;
            line-height: 32px;
            color: #333;
            font-weight: normal;
            text-align: center;
          }
        }
      }
    }
    .service_box {
      width: 100vw;
      height: auto;
      padding: 50px 0;
      background-color: #a1a1a1;
      .service {
        width: 90vw;
        height: auto;
        margin: auto;
        h1 {
          text-align: center;
          font-weight: normal;
          .name {
            font-size: 28px;
            color: #333;
            line-height: 35px;
            margin: 15px 0;
          }
          .text {
            font-size: 16px;
            color: #666;
          }
        }
        .service_content {
          width: 100%;
          height: auto;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          .service_title {
            width: 32%;
            height: auto;
            margin-top: 20px;
            cursor: pointer;
            .img {
              width: 100%;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              text-indent: 2em;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              font-size: 14px;
              margin-top: 15px;
              color: #333;
              line-height: 26px;
            }
            &:hover {
              .img {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    .group {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-weight: normal;
        .name {
          font-size: 28px;
          color: #333;
          line-height: 35px;
          margin: 15px 0;
          text-align: center;
        }
        .text {
          width: 95%;
          font-size: 16px;
          color: #666;
          margin: auto;
        }
      }
      .group_content {
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .group_left {
          width: 100%;
          height: auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          grid-row-gap: 1px;
          grid-column-gap: 1px;
          img {
            width: 49.7%;
            height: auto;
          }
        }
        .group_right {
          width: auto;
          margin-top: 2px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .about {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
    }
    .introduce {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-size: 28px;
        color: #333;
        font-weight: normal;
        text-align: center;
      }
      .text {
        margin: 0px auto;
        overflow: hidden;
        line-height: 30px;
        font-size: 14px;
        color: #333;
        margin-top: 30px;
        text-indent: 2em;
      }
      .img_box {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .img_title {
          width: 100%;
          margin-bottom: 10px;
          .img {
            width: 100%;
            text-align: center;

            img {
              width: auto;
              height: 100%;
            }
          }
          .img_text {
            margin-top: 10px;
            font-size: 15px;
            line-height: 32px;
            color: #333;
            font-weight: normal;
            text-align: center;
          }
        }
      }
    }
    .service_box {
      width: 100vw;
      height: auto;
      padding: 50px 0;
      background-color: #a1a1a1;
      .service {
        width: 90vw;
        height: auto;
        margin: auto;
        h1 {
          text-align: center;
          font-weight: normal;
          .name {
            font-size: 28px;
            color: #333;
            line-height: 35px;
            margin: 15px 0;
          }
          .text {
            font-size: 16px;
            color: #666;
          }
        }
        .service_content {
          width: 100%;
          height: auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          .service_title {
            width: 80%;
            height: auto;
            margin-top: 20px;
            cursor: pointer;
            .img {
              width: 100%;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              text-indent: 2em;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              font-size: 14px;
              margin-top: 15px;
              color: #333;
              line-height: 26px;
            }
            &:hover {
              .img {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    .group {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-weight: normal;
        .name {
          font-size: 28px;
          color: #333;
          line-height: 35px;
          margin: 15px 0;
          text-align: center;
        }
        .text {
          width: 95%;
          font-size: 16px;
          color: #666;
          margin: auto;
        }
      }
      .group_content {
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .group_left {
          width: 100%;
          height: auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          grid-row-gap: 1px;
          grid-column-gap: 1px;
          img {
            width: 49.7%;
            height: auto;
          }
        }
        .group_right {
          width: auto;
          margin-top: 2px;
          overflow: visible;
          img {
            width: 100%;
            position: relative;
            left: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .about {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
    }
    .introduce {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-size: 28px;
        color: #333;
        font-weight: normal;
        text-align: center;
      }
      .text {
        margin: 0px auto;
        overflow: hidden;
        line-height: 30px;
        font-size: 14px;
        color: #333;
        margin-top: 30px;
        text-indent: 2em;
      }
      .img_box {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .img_title {
          width: 100%;
          margin-bottom: 10px;
          .img {
            width: 100%;
            text-align: center;

            img {
              width: auto;
              height: 100%;
            }
          }
          .img_text {
            margin-top: 10px;
            font-size: 15px;
            line-height: 32px;
            color: #333;
            font-weight: normal;
            text-align: center;
          }
        }
      }
    }
    .service_box {
      width: 100vw;
      height: auto;
      padding: 50px 0;
      background-color: #a1a1a1;
      .service {
        width: 90vw;
        height: auto;
        margin: auto;
        h1 {
          text-align: center;
          font-weight: normal;
          .name {
            font-size: 28px;
            color: #333;
            line-height: 35px;
            margin: 15px 0;
          }
          .text {
            font-size: 16px;
            color: #666;
          }
        }
        .service_content {
          width: 100%;
          height: auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          .service_title {
            width: 80%;
            height: auto;
            margin-top: 20px;
            cursor: pointer;
            .img {
              width: 100%;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              text-indent: 2em;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              font-size: 14px;
              margin-top: 15px;
              color: #333;
              line-height: 26px;
            }
            &:hover {
              .img {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    .group {
      width: 90vw;
      padding: 50px 0;
      margin: auto;
      h1 {
        font-weight: normal;
        .name {
          font-size: 28px;
          color: #333;
          line-height: 35px;
          margin: 15px 0;
          text-align: center;
        }
        .text {
          width: 95%;
          font-size: 16px;
          color: #666;
          margin: auto;
        }
      }
      .group_content {
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .group_left {
          width: 100%;
          height: auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: start;
          grid-row-gap: 2px;
          grid-column-gap: 2px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .group_right {
          width: 100%;
          margin-top: 2px;
          overflow: visible;
          img {
            width: 100%;
            position: relative;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
